
import { defineComponent } from 'vue'
import PokedexAPIFactory from './api/PokedexAPIFactory'
import { Name } from '@/api/model'
import Search from './components/Search.vue'
import Constants from './Constants'

const debug = Constants.debug

export default defineComponent({
	name: 'App',
	components: {
		Search,
	},
	data() {
		return {
			pokemons: [],
			selectedPokemonName: '',
			filterText: '',
			api: PokedexAPIFactory.getPokedexAPI(),
			lang: Constants.lang,
			error: false,
			isLoading: false,
		}
	},
	computed: {
		filteredPokemon() {
			if (this.filterText.length > 0) {
				let filterTextLowercase = this.filterText.toLowerCase()
				return this.pokemons.filter(pokemon => {
					return (
						this.localize(pokemon.species.names)
							.toLowerCase()
							.indexOf(filterTextLowercase) > -1
					)
				})
			}
			return this.pokemons
		},
		loadingButtonText(): string {
			if (this.isLoading) {
				return 'Loading Pokemon...'
			}

			return `Load ${this.loadMoreCount} more`
		},
	},
	methods: {
		localize(names: [Name]): string {
			return this.api.localize(names, this.lang)
		},
		performSearch(val) {
			debug && console.log('input changed', val)
			this.filterText = val
		},
		async fetchPokemon(offset, count) {
			this.isLoading = true
			try {
				this.pokemons = await this.api.getPokemonList(offset, count)
				this.pokemonCount = this.pokemons.length
			} catch (error) {
				console.error('failed to fetch pokemon list, error: ', error)
				this.error = true
			} finally {
				this.isLoading = false
			}
		},
	},
	watch: {
		async pokemonCount(to, from) {
			const diff = to - from
			this.fetchPokemon(from, diff)
		},
		$route(to) {
			const selectedPokemon = to.params.name
			if (typeof selectedPokemon === 'string') {
				this.selectedPokemonName = selectedPokemon
			}
		},
	},
	mounted() {
		this.fetchPokemon(0, 10000)
	},
})
