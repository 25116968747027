
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'Search',
	data() {
		return {
			searchText: this.textValue,
		}
	},
	emits: ['search'],
	methods: {
		onSearch() {
			this.$emit('search', this.searchText)
		},
	},
	props: {
		textValue: String,
		placeholderText: String,
	},
})
