
import {
	Name,
	PokemonBasicInfo,
	PokemonSpecies,
	PokemonMove,
} from '@/api/model'
import { defineComponent } from 'vue'
import PokedexAPIFactory from '../api/PokedexAPIFactory'

export default defineComponent({
	name: 'PokemonInfo',
	props: {
		// the language to display
		lang: String,
	},
	data() {
		return {
			noPokemonSelected: false,
			api: PokedexAPIFactory.getPokedexAPI(),
			basicInfo: null as PokemonBasicInfo,
			evolutions: [] as PokemonSpecies[],
			moves: [] as PokemonMove[],
			loading: false,
			movesLoading: false,
			movesLoaded: false,
			movesError: false,
			error: false,
		}
	},
	computed: {},
	methods: {
		/** convenience method for template */
		localize(names: [Name]): string {
			return this.api.localize(names, this.lang)
		},
		scrollToTop() {
			let pokemon = document.querySelector('#pokemon')

			if (pokemon != null) {
				pokemon.scrollTop = 0
			}
		},
		async fetchPokemon(name: string) {
			this.scrollToTop()

			this.moves = []
			this.movesLoading = false
			this.movesLoaded = false
			this.movesError = false

			this.loading = true
			this.error = false
			try {
				this.basicInfo = await this.api.getBasicInfo(name)
			} catch (error) {
				console.error('Fetching pokemon failed, error:', error)
				this.error = true
			} finally {
				this.loading = false
			}

			this.fetchEvolutions()
		},
		async fetchEvolutions() {
			this.evolutions = []
			this.evolutions = await this.api.getEvolutions(this.basicInfo.species)
		},
		async fetchMoves() {
			this.moves = []
			this.movesLoading = true
			this.movesError = false

			try {
				this.moves = await this.api.getMoves(this.basicInfo.species.name)
				this.movesLoaded = true
			} catch (error) {
				console.error('failed to load moves, error:', error)
				this.movesError = true
			} finally {
				this.movesLoading = false
			}
		},
		/**
		 * Since this componnent is displayed "position: fixed" so that it does not scroll with the page,
		 * it does also not inherit the size of it's containing grid column.
		 * Thus, this method manually makes the component as wide as its parent.
		 */
		changeSizeToFitParent() {
			const selfNode = this.$refs.self
			if (selfNode !== null && selfNode.parentNode !== null) {
				// no need to adjust width in mobile view, since PokemonInfo component is fullscreen
				if (window.innerWidth < 768) {
					selfNode.style.width = 'inherit'
					return
				}

				let dimensions = getComputedStyle(selfNode.parentNode)
				let innerWidth =
					selfNode.parentNode.clientWidth -
					parseInt(dimensions.paddingLeft) -
					parseInt(dimensions.paddingRight)
				selfNode.style.width = innerWidth + 'px'
			}
		},
		reload(pokemonName: any) {
			if (typeof pokemonName === 'string') {
				this.noPokemonSelected = false
				this.fetchPokemon(pokemonName)
			} else {
				this.noPokemonSelected = true
			}
		},
	},
	watch: {
		$route(to) {
			this.reload(to.params.name)
		},
	},
	async mounted() {
		this.reload(this.$route.params.name)

		this.changeSizeToFitParent()
		window.addEventListener('resize', () => {
			this.changeSizeToFitParent()
		})
	},
})
